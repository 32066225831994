const mapsHTMLCentrum = [
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.777121830883!2d18.593823495343344!3d48.77818110860327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714c3332a67a77d%3A0xf02cb1e58579a967!2sBojnick%C3%BD+z%C3%A1mok%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovakia!3m2!1d48.7798829!2d18.5778259!5e0!3m2!1sen!2scz!4v1543172431470" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.777121830883!2d18.593823495343344!3d48.77818110860327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714c332d71b74c5%3A0x960a33ecd4670fc4!2sLipa+kr%C3%A1%C4%BEa+Mateja%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovakia!3m2!1d48.780657899999994!2d18.5774473!5e0!3m2!1sen!2scz!4v1543175315317" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42067.077104643904!2d18.567490133997953!3d48.77821855645208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714c332d98b4f3f%3A0x723dc1f38e8594c5!2sZoo+Bojnice%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovakia!3m2!1d48.781060599999996!2d18.5773746!5e0!3m2!1sen!2scz!4v1543175663204" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42067.077104643904!2d18.567018233997967!3d48.77821855645208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714c333216a98a3%3A0x86c463ceaf375edd!2sFalconers+Aquila+Bojnice%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovakia!3m2!1d48.780324!2d18.576178!5e0!3m2!1sen!2scz!4v1543175701044" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42067.105095046834!2d18.566358433997745!3d48.77818515648888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714c3335a2b3827%3A0xcf147328b2441a8b!2sThermal+pool+%C4%8Cajka%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovakia!3m2!1d48.7782422!2d18.5748584!5e0!3m2!1sen!2scz!4v1543175733101" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42067.105095046834!2d18.567232833997732!3d48.77818515648888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714dcccab512923%3A0x56b31e4ccd2dcaaf!2sDino+Park%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovakia!3m2!1d48.7787119!2d18.576977799999998!5e0!3m2!1sen!2scz!4v1543175754317" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42067.077104643904!2d18.57065638399795!3d48.77821855645208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714dccc1f2da12f%3A0x82621c617c4a1405!2zTcO6emV1bSBwcmF2ZWt1IFByZXBvxaF0c2vDoSBqYXNrecWIYSwgUnlibsOtxI1reSwgQm9qbmljZSwgU2xvdmFraWE!3m2!1d48.777898099999994!2d18.5834543!5e0!3m2!1sen!2scz!4v1543175783571" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42067.077104643904!2d18.570272583997976!3d48.77821855645208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714c32a861377bd%3A0x400f7d1c6971cc0!2sBojnice%2C+Slovakia!3m2!1d48.7788386!2d18.5826867!5e0!3m2!1sen!2scz!4v1543175808685" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42067.84180736822!2d18.565334233991923!3d48.77730605745784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714c3354f357e13%3A0xd8d2d926f45d51bd!2sBojnice+-+k%C3%BApele%2C+Slovakia!3m2!1d48.7743!2d18.57281!5e0!3m2!1sen!2scz!4v1543175830328" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42068.470364067!2d18.566301133986943!3d48.77655600828455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714dcb42b05af9b%3A0x5bbcd6befdaf3ea2!2zS8O6cGXEvm7DoSwgOTcyIDAxIEJvam5pY2UsIFNsb3Zha2lh!3m2!1d48.7696002!2d18.577608299999998!5e0!3m2!1sen!2scz!4v1543175912975" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d24301.457713690314!2d18.58040084884055!3d48.777594619832016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714dcc764be1689%3A0x2916aaf31e31db35!2sLetiskov%C3%A1%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7709006!2d18.5914356!5e0!3m2!1sen!2scz!4v1543175985639" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42089.90045644392!2d18.589248733817517!3d48.750978586472606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714dbeba3161e6f%3A0xa086a1982fcdc98e!2sGolf+Club+Scotland%2C+Priehon%2C+Sebedra%C5%BEie%2C+Slovakia!3m2!1d48.724698499999995!2d18.6364903!5e0!3m2!1sen!2scz!4v1543176011030" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42091.03320706509!2d18.588417583808585!3d48.74962633796268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714dbea693f3db9%3A0x23fbb5f09876f059!2sVodny+Svet+Restaurant%2C+Sebedra%C5%BEie%2C+Slovakia!3m2!1d48.722443999999996!2d18.634828!5e0!3m2!1sen!2scz!4v1543176069279" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d21043.923881373397!2d18.609424840545213!3d48.75342896801906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714dbf32248e18f%3A0x8ad726d4cb1f3e40!2sHornonitriansk%C3%BD+bansk%C3%BD+skanzen%2C+Sebedra%C5%BEie%2C+Slovakia!3m2!1d48.7340613!2d18.6393385!5e0!3m2!1sen!2scz!4v1543176095623" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d21033.54873540641!2d18.59402119058587!3d48.77819425422082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza%2C+Slovakia!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714dcc41f6bf55d%3A0xc8bb0436b6015398!2sMinigolf+Prievidza%2C+Rie%C4%8Dna%2C+Prievidza-Ukrnisk%C3%A1%2C+Slovakia!3m2!1d48.775031999999996!2d18.595164399999998!5e0!3m2!1sen!2scz!4v1543176139053" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '',
  '',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d168010.65391615892!2d18.412344377753215!3d48.85503595820276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Prievidza!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714ecf8af41e021%3A0x400f7d1c6972fe0!2zxIxpxI1tYW55!3m2!1d48.9550537!2d18.515946!5e0!3m2!1sen!2ssk!4v1543769966879" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d2629.3819402236295!2d18.62337826558976!3d48.77459816455715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e2!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Necpalsk%C3%A1+cesta+%C4%8D.+16%2C+971+01+Prievidza!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714dcf1397f6c27%3A0x9d54cc00e6548e1!2sN%C3%A1mestie+slobody%2C+971+01+Prievidza!3m2!1d48.772033199999996!2d18.624170799999998!5e0!3m2!1sen!2ssk!4v1543770402827" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d21033.086889656515!2d18.576287471059246!3d48.779296453635226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Prievidza!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714c3a0ea6a0b73%3A0xa5583b22be08bbf4!2zVsO9aGxpYWRrb3bDoSBWZcW-YSBCb2puaWNlLCBCb2puaWNl!3m2!1d48.7839179!2d18.5659275!5e0!3m2!1sen!2ssk!4v1543769925361" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d84157.15243263301!2d18.481856070897038!3d48.7644956345841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel+Squash%2C+Prievidza!3m2!1d48.7770984!2d18.6264286!4m5!1s0x4714c3d132334321%3A0xc1c3153e5ff0d47e!2sPriehrada%2C+Nitrianske+Rudno!3m2!1d48.8026987!2d18.4791448!5e0!3m2!1sen!2ssk!4v1543770092599" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d2629.2083172436246!2d18.624692915915823!3d48.777913114323525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel%20Squash%20Centrum%2C%20Necpalsk%C3%A1%20cesta%20%C4%8D.%2016%2C%20971%2001%20Prievidza%2C%20Slovensko!3m2!1d48.7770843!2d18.626557299999998!4m5!1s0x4714dd856206aaeb%3A0xe1cbbb6e39f5bb07!2sWAN%20DEE%20DEE%20-%20Thajsk%C3%A9%20mas%C3%A1%C5%BEe%2C%20Necpalsk%C3%A1%20cesta%2C%20Prievidza%2C%20Slovensko!3m2!1d48.778565099999994!2d18.6276337!5e0!3m2!1scs!2scz!4v1599996774442!5m2!1scs!2scz" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42076.2094301461!2d18.670246280357514!3d48.767320368337124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714dcfa4b09754d%3A0x7beaeb4270f732d0!2sHotel%20Squash%20Centrum%2C%20Necpalsk%C3%A1%20cesta%2C%20Prievidza%2C%20Slovensko!3m2!1d48.7770843!2d18.626557299999998!4m5!1s0x4714df7a0e6bd731%3A0xc1994e84cf595a6c!2zT0tSQVNOw4kgUllCTsONS1kgUkVNQVRBLCBSRU1BVEEgUsOBWkNFU1RJRSBSw4FaVE_EjE5PLCBSw6F6dG_EjW5vLCBTbG92ZW5za28!3m2!1d48.751987!2d18.787871!5e0!3m2!1ssk!2scz!4v1679992940232!5m2!1ssk!2scz" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" width="100%" height="100%"></iframe>',
]

const mapsHTMLDependance = [
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.777121830888!2d18.59368274534334!3d48.77818110860324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714c3332a67a77d%3A0xf02cb1e58579a967!2sBojnick%C3%BD+z%C3%A1mok%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovensko!3m2!1d48.7798829!2d18.5778259!5e0!3m2!1scs!2scz!4v1548083209466" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.777121830883!2d18.593823495343344!3d48.77818110860327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714c332d71b74c5%3A0x960a33ecd4670fc4!2sLipa+kr%C3%A1%C4%BEa+Mateja%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovensko!3m2!1d48.780657899999994!2d18.5774473!5e0!3m2!1scs!2scz!4v1548083357742" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.777121830888!2d18.593754695343346!3d48.77818110860324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714c332d98b4f3f%3A0x723dc1f38e8594c5!2sZoo+Bojnice%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovensko!3m2!1d48.781060599999996!2d18.5773746!5e0!3m2!1scs!2scz!4v1548083395899" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.777121830888!2d18.59328279534336!3d48.77818110860324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714c333216a98a3%3A0x86c463ceaf375edd!2sSokoliari+Aquila+Bojnice%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovensko!3m2!1d48.780324!2d18.576178!5e0!3m2!1scs!2scz!4v1548083418774" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.546198944354!2d18.5926229953438!3d48.77928330829449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714c3335a2b3827%3A0xcf147328b2441a8b!2sTerm%C3%A1lne+k%C3%BApalisko+%C4%8Cajka%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovensko!3m2!1d48.7782422!2d18.5748584!5e0!3m2!1scs!2scz!4v1548083444086" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.54619894436!2d18.59349739534379!3d48.77928330829446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714dcccab512923%3A0x56b31e4ccd2dcaaf!2sDino+Park%2C+Z%C3%A1mok+a+okolie%2C+Bojnice%2C+Slovensko!3m2!1d48.7787119!2d18.576977799999998!5e0!3m2!1scs!2scz!4v1548083464479" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.777121830888!2d18.596920945343342!3d48.77818110860324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714dccc1f2da12f%3A0x82621c617c4a1405!2zTcO6emV1bSBwcmF2ZWt1IFByZXBvxaF0c2vDoSBqYXNrecWIYSwgUnlibsOtxI1reSwgQm9qbmljZSwgU2xvdmVuc2tv!3m2!1d48.777898099999994!2d18.5834543!5e0!3m2!1scs!2scz!4v1548083493203" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.777121830883!2d18.596537145343344!3d48.77818110860327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714c32a861377bd%3A0x400f7d1c6971cc0!2sBojnice%2C+Slovensko!3m2!1d48.7788386!2d18.5826867!5e0!3m2!1scs!2scz!4v1548083522151" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.968297559644!2d18.591598795342986!3d48.77726860885891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714c3354f357e13%3A0xd8d2d926f45d51bd!2sBojnice+-+k%C3%BApele%2C+Slovensko!3m2!1d48.7743!2d18.57281!5e0!3m2!1scs!2scz!4v1548083542454" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10518.494133021959!2d18.59149281173676!3d48.76998519618416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714dcb42b05af9b%3A0x5bbcd6befdaf3ea2!2zS8O6cGXEvm7DoSwgOTcyIDAxIEJvam5pY2UsIFNsb3ZlbnNrbw!3m2!1d48.7696002!2d18.577608299999998!5e0!3m2!1scs!2scz!4v1548083650870" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.989216736383!2d18.599687945342932!3d48.77716875888685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714dcc764be1689%3A0x2916aaf31e31db35!2sLetiskov%C3%A1%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7709006!2d18.5914356!5e0!3m2!1scs!2scz!4v1548083704345" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42089.90866578248!2d18.58924873914561!3d48.7509687864764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714dbeba3161e6f%3A0xa086a1982fcdc98e!2sGolf+Club+Scotland%2C+Priehon%2C+Sebedra%C5%BEie%2C+Slovensko!3m2!1d48.724698499999995!2d18.6364903!5e0!3m2!1scs!2scz!4v1548083730697" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42091.04141623374!2d18.588417589136803!3d48.74961653796648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714dbea693f3db9%3A0x23fbb5f09876f059!2sVodny+Svet+Restaurant%2C+Sebedra%C5%BEie%2C+Slovensko!3m2!1d48.722443999999996!2d18.634828!5e0!3m2!1scs!2scz!4v1548083756371" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d21043.019402227288!2d18.612036091880626!3d48.75558831681417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714dbf32248e18f%3A0x8ad726d4cb1f3e40!2sHornonitriansk%C3%BD+bansk%C3%BD+skanzen%2C+Sebedra%C5%BEie%2C+Slovensko!3m2!1d48.7340613!2d18.6393385!5e0!3m2!1scs!2scz!4v1548083777273" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d10516.777121830888!2d18.602775995343336!3d48.77818110860324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714dcc41f6bf55d%3A0xc8bb0436b6015398!2sMinigolf+Prievidza%2C+Rie%C4%8Dna%2C+Prievidza-Ukrnisk%C3%A1%2C+Slovensko!3m2!1d48.775031999999996!2d18.595164399999998!5e0!3m2!1scs!2scz!4v1548083799709" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '',
  '',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d168010.65391795422!2d18.412345710978!3d48.855035957667816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714ecf8af41e021%3A0x400f7d1c6972fe0!2zxIxpxI1tYW55LCBTbG92ZW5za28!3m2!1d48.9550537!2d18.515946!5e0!3m2!1scs!2scz!4v1548083821423" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d1314.658703291648!2d18.625066158339838!3d48.775830312414485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e2!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714dc687610399d%3A0x400f7d1c6971cb0!2sPrievidza%2C+Slovensko!3m2!1d48.774520599999995!2d18.6245384!5e0!3m2!1scs!2scz!4v1548084398310" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d21033.45617413331!2d18.57696454191751!3d48.7784151540958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714c3a0ea6a0b73%3A0xa5583b22be08bbf4!2sV%C3%BDhliadkov%C3%A1+Ve%C5%BEa+Bojnice%2C+Bojnice%2C+Slovensko!3m2!1d48.7839179!2d18.5659275!5e0!3m2!1scs!2scz!4v1548083842184" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42078.61028623358!2d18.51701753923351!3d48.7644549716146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel+Squash+Dependance%2C+B.+Slan%C4%8D%C3%ADkovej+544%2F8%2C+971+01+Prievidza%2C+Slovensko!3m2!1d48.7771365!2d18.627644!4m5!1s0x4714c3d132334321%3A0xc1c3153e5ff0d47e!2sPriehrada%2C+Nitrianske+Rudno%2C+Slovensko!3m2!1d48.8026987!2d18.4791448!5e0!3m2!1scs!2scz!4v1548083864390" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d2629.207942747527!2d18.625262815915736!3d48.777920264323015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel%20Squash%20Dependance%2C%20Bo%C5%BEeny%20Slan%C4%8D%C3%ADkovej%2C%20Prievidza%2C%20Slovensko!3m2!1d48.777134499999995!2d18.627606399999998!4m5!1s0x4714dd856206aaeb%3A0xe1cbbb6e39f5bb07!2sWAN%20DEE%20DEE%20-%20Thajsk%C3%A9%20mas%C3%A1%C5%BEe%2C%20Necpalsk%C3%A1%20cesta%2C%20Prievidza%2C%20Slovensko!3m2!1d48.778565099999994!2d18.6276337!5e0!3m2!1scs!2scz!4v1599997821979!5m2!1scs!2scz" width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>',
  '<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d42076.2094301461!2d18.670246280357514!3d48.767320368337124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x4714ddda32590be3%3A0xe9aa44bbb781dde4!2sHotel%20Squash%20Dependance%2C%20Bo%C5%BEeny%20Slan%C4%8D%C3%ADkovej%2C%20Prievidza%2C%20Slovensko!3m2!1d48.777134499999995!2d18.627606399999998!4m5!1s0x4714df7a0e6bd731%3A0xc1994e84cf595a6c!2zT0tSQVNOw4kgUllCTsONS1kgUkVNQVRBLCBSRU1BVEEgUsOBWkNFU1RJRSBSw4FaVE_EjE5PLCBSw6F6dG_EjW5vLCBTbG92ZW5za28!3m2!1d48.751987!2d18.787871!5e0!3m2!1ssk!2scz!4v1679993013476!5m2!1ssk!2scz" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" width="100%" height="100%"></iframe>',
]

export { mapsHTMLCentrum, mapsHTMLDependance }
